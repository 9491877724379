
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useFetchCommon from '@/hooks/useFetchCommon';
import {
    getDealerType,
    getDealerGroup,
    getDealerDetail,
    getAllBu,
    checkDealer,
} from '@/API/dealerMaster';
import {
    DealerType,
    DealerGroup,
    Bu,
} from '@/views/DealerMasterData/Region/types';
import {
    DealerDetail,
    DealerRegionMappingDto,
    DealerTypeMappingDto,
} from './types';
import { updateDealerInfo } from '@/API/dealerMaster';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'DealerEdit',
    props: {
        dealerId: {
            type: String,
        },
    },
    setup(props) {
        const { back } = useRouter();

        const buValue = ref<Bu[]>([]);

        const { data: dealerGroup } = useFetchCommon<DealerGroup[]>({
            initData: [],
            fetchApi: getDealerGroup,
        });

        const active = ref(false);

        const initData = {
            tmDealerInfoDto: {
                id: '',
                dealerCode: '',
                gsCode: '',
                gcCode: '',
                statusName: '',
                nameCn: '',
                nameEn: '',
                dealerGroup: '',
                dealerGroupNameCn: '',
                dealerGroupNameEn: '',
                provinceEn: '',
                provinceCn: '',
                cityCn: '',
                cityEn: '',
                legalNameCn: '',
                legalNameEn: '',
                additionalNameCn: '',
                additionalNameEn: '',
                addressCn: '',
                addressEn: '',
                zipCode: '',
                format: '',
                contactEmail: '',
                fixPhone: '',
                mobilePhone: '',
                fax: '',
                mbafcCode: null,
                swtCode: null,
                vendorCode: null,
                isGovernmentRelated: 0,
                languageValue: null,
                dosCode: null,
            },
            tmDealerDealerTypeMappingDto: [],
            tmDealerRegionMappingDto: [],
        };

        const data = reactive<DealerDetail>(initData);

        const type = ref<DealerType[]>([]);

        const bu = ref<Bu[]>([]);

        const { t } = useI18n();

        const dealerInfoMapping = reactive([
            [
                {
                    name: t('dealerDataEdit.legalNameCn'),
                    key: 'legalNameCn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.legalNameEn'),
                    key: 'legalNameEn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.additionalNameCn'),
                    key: 'additionalNameCn',
                    type: 'text',
                },
            ],
            [
                {
                    name: t('dealerDataEdit.additionalNameEn'),
                    key: 'additionalNameEn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.provinceCn'),
                    key: 'provinceCn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.provinceEn2'),
                    key: 'provinceEn',
                    type: 'text',
                },
            ],
            [
                {
                    name: t('dealerDataEdit.cityCn'),
                    key: 'cityCn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.cityEn2'),
                    key: 'cityEn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.addressCn'),
                    key: 'addressCn',
                    type: 'text',
                },
            ],
            [
                {
                    name: t('dealerDataEdit.addressEn'),
                    key: 'addressEn',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.zipCode'),
                    key: 'zipCode',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.format'),
                    key: 'format',
                    type: 'text',
                },
            ],
            [
                {
                    name: t('dealerDataEdit.contactEmail'),
                    key: 'contactEmail',
                    type: 'text',
                },
                {
                    name: t('dealerDataEdit.fixPhone'),
                    key: 'fixPhone',
                    type: 'text',
                },
                // {
                //    t( dealerDataEdit.name: 'MobilePhone'),
                //     key: 'mobilePhone',
                //     type: 'text'
                // },
                {
                    name: t('dealerDataEdit.dealerGroup2'),
                    key: 'dealerGroup',
                    type: 'select',
                    source: dealerGroup,
                },
            ],
            [
                {
                    name: t('dealerDataEdit.swtCode'),
                    key: 'swtCode',
                    type: 'input',
                },
                {
                    name: t('dealerDataEdit.vendorCode'),
                    key: 'vendorCode',
                    type: 'input',
                },
                {
                    name: t('dealerDataEdit.mbafcCode'),
                    key: 'mbafcCode',
                    type: 'input',
                },
            ],
            [
                {
                    name: t('dealerDataEdit.languageValue'),
                    key: 'languageValue',
                    type: 'input',
                },
                {
                    name: t('dealerDataEdit.dosCode'),
                    key: 'dosCode',
                    type: 'input',
                },
                {
                    name: t('dealerDataEdit.isGovernmentRelated'),
                    key: 'isGovernmentRelated',
                    type: 'radio',
                    source: [
                        {
                            name: t('dealerDataEdit.yes'),
                            value: 1,
                        },
                        {
                            name: t('dealerDataEdit.no'),
                            value: 0,
                        },
                    ],
                },
            ],
            [
                {
                    name: '',
                    key: '',
                    type: 'text',
                },
            ],
        ]);

        const handlerSave = () => {
            const regionValue = bu.value
                .map((item) =>
                    item.buRegionValue
                        ? {
                              buId: item.buRegionValue.buId,
                              dealerInfoId: props.dealerId,
                              regionId: item.buRegionValue.id,
                              id: item.uuid,
                          }
                        : null
                )
                .filter((item) => item);

            const dealerTypeValue = type.value
                .map((item) =>
                    item.poccDate || item.uuid
                        ? {
                              dealerInfoId: props.dealerId,
                              dealerTypeId: item.id,
                              id: item.uuid,
                              isDelete:
                                  item.poccDate && !item.uuid
                                      ? 0
                                      : !item.poccDate && item.uuid
                                      ? 1
                                      : 0,
                              poccDate: item.poccDate,
                          }
                        : null
                )
                .filter((item) => item);

            if (data.tmDealerInfoDto.status === 1) {
                if (!data.tmDealerInfoDto.dealerCode) {
                    message.error('DealerCode 不能为空');
                    return;
                }
                checkDealer({
                    dealerInfoId: props.dealerId as string,
                    dealerCode: data.tmDealerInfoDto.dealerCode,
                }).then((res) => {
                    // 勾选了active
                    if (active.value) {
                        if (
                            dealerTypeValue.length > 0 &&
                            regionValue.length > 0
                        ) {
                            data.tmDealerInfoDto.status = active.value
                                ? 2
                                : data.tmDealerInfoDto.status;
                            data.tmDealerRegionMappingDto = regionValue as DealerRegionMappingDto[];
                            data.tmDealerDealerTypeMappingDto = dealerTypeValue as DealerTypeMappingDto[];
                            updateDealerInfo(data).then((res) => {
                                message.success(
                                    'update dealer info successfully'
                                );
                                setTimeout(() => {
                                    back();
                                }, 1000);
                            });
                        } else {
                            message.error('请完善信息');
                        }
                    } else {
                        data.tmDealerInfoDto.status = active.value
                            ? 2
                            : data.tmDealerInfoDto.status;
                        data.tmDealerRegionMappingDto = regionValue as DealerRegionMappingDto[];
                        data.tmDealerDealerTypeMappingDto = dealerTypeValue as DealerTypeMappingDto[];
                        updateDealerInfo(data).then((res) => {
                            message.success('update dealer info successfully');
                            setTimeout(() => {
                                back();
                            }, 1000);
                        });
                    }
                });
            } else {
                data.tmDealerInfoDto.status = active.value
                    ? 2
                    : data.tmDealerInfoDto.status;
                data.tmDealerRegionMappingDto = regionValue as DealerRegionMappingDto[];
                data.tmDealerDealerTypeMappingDto = dealerTypeValue as DealerTypeMappingDto[];
                updateDealerInfo(data).then((res) => {
                    message.success('update dealer info successfully');
                    setTimeout(() => {
                        back();
                    }, 1000);
                });
            }
        };

        const getDealerDetailData = async () => {
            const _dealerType = await getDealerType();
            const _bu = await getAllBu();
            const dealerDetailData = await getDealerDetail({
                dealerId: props.dealerId as string,
            });

            if (dealerDetailData.tmDealerDealerTypeMappingDto.length > 0) {
                _dealerType.forEach((item) => {
                    const result = dealerDetailData.tmDealerDealerTypeMappingDto.filter(
                        (ele) => ele.dealerTypeId == item.id
                    );
                    if (result.length > 0) {
                        item.poccDate = result[0].poccDate;
                        item.uuid = result[0].id;
                    } else {
                        item.poccDate = '';
                    }
                });
            }

            if (dealerDetailData.tmDealerRegionMappingDto.length > 0) {
                _bu.forEach((bu) => {
                    const result = dealerDetailData.tmDealerRegionMappingDto.filter(
                        (ele) => ele.buId == bu.id
                    );
                    if (result.length > 0) {
                        bu.buRegionValue = (() =>
                            bu.regionDto?.filter(
                                (item) => item.id === result[0].regionId
                            )[0] ?? null)();
                        bu.uuid = result[0].id;
                        buValue.value.push(bu);
                    } else {
                        bu.buRegionValue = null;
                        bu.uuid = '';
                    }
                });
            }

            Object.assign(data, dealerDetailData);

            type.value = _dealerType;
            bu.value = _bu;
        };

        const handlerCheckboxChange = () => {
            if (!data.tmDealerInfoDto.dealerCode) {
                message.error('DealerCode 不能为空');
                return;
            }
            checkDealer({
                dealerInfoId: props.dealerId as string,
                dealerCode: data.tmDealerInfoDto.dealerCode,
            })
                .then((res) => {
                    active.value = !active.value;
                })
                .catch((e) => {
                    active.value = false;
                });
        };

        const handlerBuCheckboxChange = (value: Bu[]) => {
            bu.value.forEach((bu) => {
                if (!value.some((item) => item.id === bu.id)) {
                    bu.buRegionValue = null;
                }
            });
        };

        onMounted(() => {
            getDealerDetailData();
        });

        return {
            type,
            data,
            active,

            dealerGroup,
            bu,
            buValue,

            dealerInfoMapping,

            handlerSave,
            handlerCheckboxChange,
            handlerBuCheckboxChange,
        };
    },
});
